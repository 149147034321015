<template>
  <div id="Vreg">
    <form
      class="dtmform xs-donation-form"
      @submit.prevent="formsubmit"
      v-if="!data"
    >
      <div class="from-row">
        <div class="col-md-12 md-4 text-center">
          <h2>Volunteer Registration Form</h2>
        </div>
        <div
          class="alert alert-warning alert-dismissible fade show"
          v-if="errors"
          role="alert"
        >
          <strong>Error!</strong> {{ errors.message }} {{ data }}
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <hr />
      <div class="form-row">
        <div class="col-md-6 mb-3">
          <label for="validationDefault01">
            Full Name
            <span style="color: red">*</span>
          </label>
          <input
            type="text"
            class="form-control"
            id="validationDefault01"
            placeholder="Full Name"
            v-model="fullName"
            required
          />
        </div>
        <div class="col-md-6 mb-3">
          <label for="validationDefault02">
            Father's Name
            <span style="color: red">*</span>
          </label>
          <input
            type="text"
            class="form-control"
            id="validationDefault02"
            v-model="fatherName"
            placeholder="Father's Name"
            required
          />
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-6 mb-3">
          <label for="validationDefault02">
            Mother's Name
            <span style="color: red">*</span>
          </label>
          <input
            type="text"
            class="form-control"
            id="validationDefault02"
            v-model="fatherName"
            placeholder="Mother's Name"
            required
          />
        </div>
        <div class="col-md-6 mb-3">
          <label for="validationDefault05">
            Primery Contact Number
            <span style="color: red">*</span>
          </label>
          <input
            type="text"
            class="form-control"
            id="validationDefault05"
            v-model="mobile1"
            placeholder="01800000000"
            required
          />
        </div>
        
        
      </div>
      <div class="form-row">
        <div class="col-md-6 mb-3">
          <label for="validationDefault04">NID/Birth Reg Number</label>
          <input
            type="text"
            class="form-control"
            id="validationDefault04"
            placeholder="xxxxxxxxxx"
            v-model="email"
          />
        </div>
        <div class="col-md-2 mb-3">
          <label for="day">
            Birth Date
            <span style="color: red">*</span>
          </label>
          <select class="custom-select" id="day" v-model="dd" required>
            <option selected disabled value="">Choose...</option>
            <option v-for="(day, index) in birth.dd" :key="index" :value="day">
              {{ day }}
            </option>
          </select>
        </div>
        <div class="col-md-2 mb-3">
          <label for="month">
            Birth Month
            <span style="color: red">*</span>
          </label>
          <select class="custom-select" id="month" v-model="mm" required>
            <option selected disabled value="">Choose...</option>
            <option
              v-for="(month, index) in birth.mm"
              :key="index"
              :value="month"
            >
              {{ months[index] }}
            </option>
          </select>
        </div>
        <div class="col-md-2 mb-3">
          <label for="year">
            Birth Year
            <span style="color: red">*</span>
          </label>
          <select class="custom-select" id="year" v-model="yy" required>
            <option selected disabled value="">Choose...</option>
            <option
              v-for="(year, index) in birth.yy"
              :key="index"
              :value="year"
            >
              {{ year }}
            </option>
          </select>
        </div>
      </div>
     
      
      <div class="form-row">
        <div class="col-md-6 mb-3">
          <label for="religion">
            Religion
            <span style="color: red">*</span>
          </label>
          <select
            class="custom-select"
            id="religion"
            v-model="religion"
            required
          >
            <option selected disabled value="">Choose...</option>
            <option value="Islam">Islam</option>
            <option value="Hindus">Hinduism</option>
            <option value="Buddhists">Buddhism</option>
            <option value="Christianity">Christianity</option>
            <option value="Other">Other</option>
          </select>
        </div>
        <div class="col-md-6 mb-3">
          <label for="validationDefault10">
            Gender
            <span style="color: red">*</span>
          </label>
          <select
            class="custom-select"
            id="validationDefault10"
            v-model="gender"
            required
          >
            <option selected disabled value="">Choose...</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </select>
        </div>
        <div class="col-md-6 mb-3">
          <label for="validationDefault09">
            Occupation
            <span style="color: red">*</span>
          </label>
          <input
            type="text"
            class="form-control"
            id="validationDefault09"
            v-model="occupation"
            placeholder="Business, Student etc"
            required
          />
        </div>
        <div class="col-md-6 mb-3">
          <label for="validationDefault08">School/College/Institution's Name</label>
          <input
            type="text"
            class="form-control"
            id="validationDefault08"
            placeholder="School/College/Institution's Name"
            v-model="fb"
          />
        </div>
        
        
      </div>

      <div class="form-row">
        <div class="col-md-6 mb-3">
          <label for="validationDefault14">
            Present Address
            <span style="color: red">*</span>
          </label>
          <input
            type="text"
            class="form-control"
            id="validationDefault14"
            placeholder="Permanent Address"
            v-model="permanentAddr"
            required
          />
        </div>
        <div class="col-md-3 mb-3">
          <label for="validationDefault16">
            Thana
            <span style="color: red">*</span>
          </label>
          <input
            type="text"
            class="form-control"
            id="validationDefault16"
            placeholder="Thana"
            v-model="zip2"
            required
          />
        </div>
        <div class="col-md-3 mb-3">
          <label for="validationDefault15">
            District
            <span style="color: red">*</span>
          </label>
          <select
            class="custom-select"
            id="validationDefault15"
            v-model="city2"
            required
          >
            <option selected disabled value="">Choose...</option>
            <option
              v-for="apicity in apicitys"
              :key="apicity.id"
              :value="apicity.name"
            >
              {{ apicity.name }}
            </option>
          </select>
        </div>
       
      </div>
      
      <div class="form-row">
        <div class="col-md-6 mb-3">
          <label for="validationDefault14">
            Permanent Address
            <span style="color: red">*</span>
          </label>
          <input
            type="text"
            class="form-control"
            id="validationDefault14"
            placeholder="Permanent Address"
            v-model="permanentAddr"
            required
          />
        </div>
        <div class="col-md-3 mb-3">
          <label for="validationDefault16">
            Thana
            <span style="color: red">*</span>
          </label>
          <input
            type="text"
            class="form-control"
            id="validationDefault16"
            placeholder="Thana"
            v-model="zip2"
            required
          />
        </div>
        <div class="col-md-3 mb-3">
          <label for="validationDefault15">
            District
            <span style="color: red">*</span>
          </label>
          <select
            class="custom-select"
            id="validationDefault15"
            v-model="city2"
            required
          >
            <option selected disabled value="">Choose...</option>
            <option
              v-for="apicity in apicitys"
              :key="apicity.id"
              :value="apicity.name"
            >
              {{ apicity.name }}
            </option>
          </select>
        </div>

      </div>
      <div class="form-row">
        <div class="col-md-6 mb-3">
          <label for="validationDefault17"> Reference </label>
          <input
            type="text"
            class="form-control"
            id="validationDefault17"
            v-model="reference"
            placeholder="Facebook, Volunteer Name, Others....."
          />
        </div>
      </div>
      <div class="form-group">
        <div class="form-check checkboxmd">
          <input
            class="form-check-input"
            type="checkbox"
            v-model="trams"
            id="invalidCheck2"
            required
          />
          <label class="form-check-label" for="invalidCheck2">
            Agree to terms and conditions
          </label>
        </div>
      </div>
      <button class="btn btn-primary" type="submit">Submit</button>
    </form>
    <!-- start payment template  -->
    <div class="row dtmform" v-if="data">
      <div class="col-md-4 mb-4">
        <b>Full Name: </b> {{ data.info.fullName }}
      </div>
      <div class="col-md-4 mb-4">
        <b> Praimery Mobile Number: </b> {{ data.info.mobile1 }}
      </div>
      <div class="col-md-4 mb-4">
        <b> Daily Ten Member Registration Fee: </b>
        <span style="color: green"> 200 BDT.</span>
      </div>
    </div>
    <div class="dtmform" v-if="data">
      <b style="color: red; font-size: 20px">Manual Payment</b>
      <hr />
      <div class="row">
        <div
          class="col-md-3 col-lg-2 col-sm-4 mb-4"
          v-for="(getm, index) in getwaymanual"
          :key="index"
        >
          <div class="card mb-3" style="width: 150px; height: 220px">
            <a :href="getm.redirectGatewayURL" class="">
              <img
                class="card-img-top"
                :src="getm.logo"
                :alt="getm.gw"
                style="width: 150px; height: 150px"
              />
              <div class="card-body">
                <b class="">{{ getm.name }}</b>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="dtmform" v-if="data">
      <b style="color: red; font-size: 20px">SSLCommerz Payment Getway</b>
      <hr />
      <div class="row">
        <div
          class="col-md-3 col-lg-2 col-sm-4 mb-4"
          v-for="(get, index) in data.getway.desc"
          :key="index"
        >
          <div class="card mb-3" style="width: 140px; height: 220px">
            <a :href="get.redirectGatewayURL" class="">
              <img
                class="card-img-top"
                :src="get.logo"
                :alt="get.gw"
                style="width: 140px; height: 140px"
              />
              <div class="card-body">
                <b class="">{{ get.name }}</b>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";

export default {
  name: "Vreg",
  components: {
    // HelloWorld,
  },
  data() {
    return {
      fullName: "",
      fatherName: "",
      dd: "",
      mm: "",
      yy: "",
      email: "",
      mobile1: "",
      religion: "",
      gender: "",
      fb: "",
      occupation: "",
      presentAddr: "",
      permanentAddr: "",
      reference: "",
      trams: "",
      apicitys: [],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      birth: {
        dd: [],
        mm: [],
        yy: [],
      },
      errors: null,
      data: null,
      getwaymanual: [
        {
          gw: "Bkash Manual",
          logo: "https://sandbox.sslcommerz.com/gwprocess/v4/image/gw/amex.png",
          name: "Bkash",
          r_flag: "1",
          redirectGatewayURL:
            "https://sandbox.sslcommerz.com/gwprocess/v4/bankgw/indexhtmlOTP.php?mamount=200.00&ssl_id=21011015622JL7RdXY0H7weKII&Q=REDIRECT&SESSIONKEY=159334C6358398C2CF21C155393A94AF&tran_type=success&cardname=amexcard",
          type: "manual",
        },
      ],
    };
  },
  methods: {
    formsubmit() {
      let fac = this;
      if (this.trams) {
        axios
          .post("http://api.yfbd.org/dtm/member/", {
            fullName: this.fullName,
            fatherName: this.fatherName,
            birth: this.yy + "-" + this.mm + "-" + this.dd,
            email: this.email,
            mobile1: this.mobile1,
            whatsapp1: this.whatsapp1,
            imo1: this.imo1,
            viber1: this.viber1,
            telegram1: this.telegram1,
            mobile2: this.mobile2,
            whatsapp2: this.whatsapp2,
            imo2: this.imo2,
            viber2: this.viber2,
            telegram2: this.telegram2,
            gender: this.gender,
            fb: this.fb,
            occupation: this.occupation,
            presentAddr: this.presentAddr,
            permanentAddr: this.permanentAddr,
            reference: this.reference,
          })
          .then(function (response) {
            fac.data = response.data;
          })
          .catch(function (error) {
            fac.errors = error;
            console.log(error);
          });
      }
    },

    async getDistrics() {
      try {
        const response = await axios.get(
          "https://gist.githubusercontent.com/RahimBangla/d632d476e501e60402bb7b00e43da2fd/raw/fa037b2ead49cfd8d7c3dbe292b15f5ecb3e132d/District.json"
        );
        this.apicitys = response.data;
        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    },
    async getCountry() {
      try {
        const response = await axios.get(
          "https://gist.githubusercontent.com/RahimBangla/b3445a39dd6aac573f226c44dbc45393/raw/923b6dc9884b7d8588b82cb3d9dde2f44b7ccb0a/countrycode.json"
        );
        this.apicountry = response.data;
        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    },
    async addchek() {
      let fac = this;
      fac.permanentAddr = fac.presentAddr;
    },
    async yearlist() {
      let fac = this;
      for (var i = 1920; i < new Date().getFullYear(); i++) {
        fac.birth.yy.push(i);
      }
    },
    async monthlist() {
      let fac = this;
      for (var i = 1; i <= 12; i++) {
        fac.birth.mm.push(i);
      }
    },
    async daylist() {
      let fac = this;
      for (var i = 1; i <= 31; i++) {
        fac.birth.dd.push(i);
      }
    },
  },
  created() {
    this.getDistrics();
    this.addchek();
    this.yearlist();
    this.monthlist();
    this.daylist();
  },
  computed() {
    this.addchek();
  },
};
</script>

<style>
.dtmform {
  padding: 20px;
  margin: 5px;
  border-radius: 10px;
  background-color: #f3f3f3;
}

@media only screen and (min-width: 768px) {
  .checkboxmd {
    padding-top: 38px;
  }
}
</style>
