<template>
  <div class="dbody">
    <div class="card">
      <div v-if="$route.params.message == 'success'">
        <div
          style="
            border-radius: 200px;
            height: 200px;
            width: 200px;
            background: #f8faf5;
            margin: 0 auto;
          "
        >
          <i class="checkmark">✓</i>
        </div>
        <h1>Successfull</h1>
      </div>
      <div v-else-if="$route.params.message == 'failed'">
        <div
          style="
            border-radius: 200px;
            height: 200px;
            width: 200px;
            background: #f8faf5;
            margin: 0 auto;
          "
        >
          <i class="checkmark" style="color: red">x</i>
        </div>
        <h1 style="color: red">Failed</h1>
      </div>
      <div v-else>
        <div
          style="
            border-radius: 200px;
            height: 200px;
            width: 200px;
            background: #f8faf5;
            margin: 0 auto;
          "
        >
          <i class="checkmark" style="color: gray">X</i>
        </div>
        <h1 style="color: gray">Canceled</h1>
      </div>
      <p>
        Payment has been {{ $route.params.message }} ;<br />
        we'll be in touch shortly!
      </p>

      <a href="https://youthforbangladesh.org">
        <b style="color: green">Home</b>
      </a>
      <br />

      <a href="/dtm" class="btn btn-success">Donate Now</a>
    </div>
  </div>
</template>

<style>
.dbody {
  text-align: center;
  padding: 40px 0;
  height: 100%;
}
h1 {
  color: #88b04b;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}
p {
  color: #404f5e;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  margin: 0;
}
i {
  color: #9abc66;
  font-size: 100px;
  line-height: 200px;
  margin-left: -15px;
}
.card {
  background: white;
  padding: 60px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #c8d0d8;
  display: inline-block;
  margin: 0 auto;
}
</style>