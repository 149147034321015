<template>
  <div class="container">
    <Registration />
  </div>
</template>

<script>
// @ is an alias to /src
import Registration from "@/components/Registrationdtm.vue";

export default {
  name: "Home",
  components: {
    Registration,
  },
};
</script>
