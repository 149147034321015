<template>
  <div class="container">
    <div class="dtmform xs-donation-form">
      <div class="from-row row">
        <div class="col-md-12 md-4 text-center">
          <h2>DTM Pending/Advance Amount Check</h2>
        </div>
        <div
          class="alert alert-warning alert-dismissible fade show"
          v-if="errors"
          role="alert"
        >
          <strong>Error!</strong> {{ errors.message }} {{ data }}
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <hr />
      <div class="text-center">
        <div class="form-row row">
          <div class="col-12 mb-3">
            <label for="number">Daily Ten Member ID</label>
            <input
              type="number"
              class="form-control"
              id="number"
              placeholder="000000"
              v-model="dtmId"
            />
          </div>
        </div>
        <button class="btn btn-primary" @click="getDistrics" type="submit">
          Submit
        </button>
      </div>
    </div>
    <!-- start payment template  -->
    <div class="row dtmform" v-if="data">
      <table class="table table-bordered">
        <tbody>
          <tr>
            <th><b>Full Name:&nbsp;&nbsp; </b></th>
            <td>{{ data.name || "Unknown" }}</td>
            <td><b>Mobile:&nbsp;&nbsp; </b></td>
            <td>{{ data.mobile || "" }}</td>
          </tr>
          <tr>
            <th><b>Pending Amount:&nbsp;&nbsp; </b></th>
            <td>
              <span style="color: red"> {{ data.panding }}</span>
            </td>
            <td><b>Advance Amount:&nbsp;&nbsp; </b></td>
            <td>
              <span style="color: green"> {{ data.advance }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";

export default {
  name: "Home",
  components: {
    // HelloWorld,
  },
  data() {
    return {
      dd: "",
      mm: "",
      yy: "",
      dtmId: "",
      number1: "",
      apicitys: [],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      birth: {
        dd: [],
        mm: [],
        yy: [],
      },
      errors: null,
      data: null,
      getwaymanual: [
        {
          gw: "Bkash Manual",
          logo: "https://sandbox.sslcommerz.com/gwprocess/v4/image/gw/amex.png",
          name: "Bkash",
          r_flag: "1",
          redirectGatewayURL:
            "https://sandbox.sslcommerz.com/gwprocess/v4/bankgw/indexhtmlOTP.php?mamount=200.00&ssl_id=21011015622JL7RdXY0H7weKII&Q=REDIRECT&SESSIONKEY=159334C6358398C2CF21C155393A94AF&tran_type=success&cardname=amexcard",
          type: "manual",
        },
      ],
    };
  },
  methods: {
    formsubmit() {
      // let fac = this;
      // if (this.trams) {
      //   axios
      //     .post("http://api.yfbd.org/dtm/member/", {
      //       fullName: this.fullName,
      //       fatherName: this.fatherName,
      //       birth: this.yy + "-" + this.mm + "-" + this.dd,
      //       email: this.email,
      //       mobile1: this.mobile1,
      //       whatsapp1: this.whatsapp1,
      //       imo1: this.imo1,
      //       viber1: this.viber1,
      //       telegram1: this.telegram1,
      //       mobile2: this.mobile2,
      //       whatsapp2: this.whatsapp2,
      //       imo2: this.imo2,
      //       viber2: this.viber2,
      //       telegram2: this.telegram2,
      //       gender: this.gender,
      //       fb: this.fb,
      //       occupation: this.occupation,
      //       presentAddr: this.presentAddr,
      //       permanentAddr: this.permanentAddr,
      //       reference: this.reference,
      //     })
      //     .then(function (response) {
      //       fac.data = response.data;
      //     })
      //     .catch(function (error) {
      //       fac.errors = error;
      //       console.log(error);
      //     });
      // }
    },

    async getDistrics() {
      try {
        const response = await axios.get(
          `https://api.yfbd.org/dtm/cheak/${this.dtmId}`
        );
        this.data = response.data;
        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    },
    // async addchek() {
    //   let fac = this;
    //   fac.permanentAddr = fac.presentAddr;
    // },
    // async yearlist() {
    //   let fac = this;
    //   for (var i = 1920; i < new Date().getFullYear(); i++) {
    //     fac.birth.yy.push(i);
    //   }
    // },
    // async monthlist() {
    //   let fac = this;
    //   for (var i = 1; i <= 12; i++) {
    //     fac.birth.mm.push(i);
    //   }
    // },
    // async daylist() {
    //   let fac = this;
    //   for (var i = 1; i <= 31; i++) {
    //     fac.birth.dd.push(i);
    //   }
    // },
  },
  created() {
    // this.getDistrics();
    // this.addchek();
    // this.yearlist();
    // this.monthlist();
    // this.daylist();
  },
  computed() {
    // this.addchek();
  },
};
</script>

<style>
.dtmform {
  padding: 20px;
  margin: 5px;
  border-radius: 10px;
  background-color: #f3f3f3;
}

@media only screen and (min-width: 768px) {
  .checkboxmd {
    padding-top: 38px;
  }
}
</style>
