<template>
  <div class="dbody">
    <div class="card">
      <div>
        <div
          style="
            border-radius: 200px;
            height: 200px;
            width: 200px;
            background: #f8faf5;
            margin: 0 auto;
          "
        >
          <i class="checkmark">✓</i>
        </div>
        <h1>Successfull</h1>
      </div>
      <p>
        You have registered as a Daily Ten Member.<br />
        Your Daily Ten Membership ID: <b style="color:red;"> {{dtm_id}} </b><br/>
        Please send <b>300</b>tk as registration fee. <br/>
        Bkash/Rocket/Nagad (Personal): <b ref="num1" id="num1">01713222343  </b> <a style="color:green;" @click="copy()"></a> <br/>
        Bkash/Rocket/Nagad (Personal):  <b ref="num2"> 01873708000 </b> <a style="color:green;" @click="copy()"></a><br/>
        Bkash/Nagad (Marchant): <b ref="num3"> 01990003339 </b> <a style="color:green;" @click="copy()"></a>
      </p>

      <a href="https://youthforbangladesh.org">
        <b style="color: green">Home</b>
      </a>
      <br />

      <a href="/dtm" class="btn btn-success">Portal</a>
    </div>
  </div>
</template>
<script>
export default {
  name: "success",
  props: ['dtm_id'],
  data() {
    return {
    }
  },
  methods: {
     copy() {
         this.$refs.num1.select();
      document.execCommand("copy");
    }
  },
  mounted() {
  },
  created() {
  },
  computed: {},
}
</script>
<style>
.dbody {
  text-align: center;
  padding: 40px 0;
  height: 100%;
}
h1 {
  color: #88b04b;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}
p {
  color: #404f5e;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  margin: 0;
}
i {
  color: #9abc66;
  font-size: 100px;
  line-height: 200px;
  margin-left: -15px;
}
.card {
  background: white;
  padding: 60px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #c8d0d8;
  display: inline-block;
  margin: 0 auto;
}
</style>