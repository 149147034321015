<template>
  <div>
    <return-vew />
  </div>
</template>

<script>
// @ is an alias to /src
import ReturnVew from "@/components/return.vue";

export default {
  components: {
    ReturnVew,
  },
};
</script>